import React from 'react';
import { ThemeProvider } from 'styled-components';

// Styles
import { GlobalStyles, darkTheme  } from 'styles/GlobalStyles';

const Layout = ({ children }) => {
   
    return(
        <ThemeProvider theme={darkTheme} >
            <GlobalStyles />
            
            { children }
        </ThemeProvider>
    )
}

export default Layout;