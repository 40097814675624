import { createGlobalStyle } from "styled-components"


export const colors = {
  // prime: "#7FBD1B",
  prime: '#8CC646',
  primeold: "#86C232",
  lightgray: "#38393D",
  darkgray: "#272d2d",
  darkgreen: "#61892f",
  lightergray: "#6b6e70",
  pcolor: "#999",

}

export const darkTheme = {
  colors: {
    prime: colors.prime,
    lightgray: colors.lightgray,
    gray: colors.gray,
    darkgray: colors.darkgray,
    darkgreen: colors.darkgreen,
    lightergray: colors.lightergray,
    pcolor: colors.pcolor
  },
}



export const GlobalStyles = createGlobalStyle`

*::-webkit-scrollbar {
    display: none;
  -ms-overflow-style: none;
    scrollbar-width: none;
}
  
#gatsby-focus-wrapper {
  max-width:1600px;
  margin:0 auto;
  overflow: hidden;
}
@media screen and (min-width: 1040px) {
  .homelinkscroll {
    font-size: 1rem !important;
    padding: 0 15px;
    padding-top:0px !important;
  }
}


  body {
    transition: all 0.5s ease;
    margin:0;
    background: rgb(20,20,20);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Poppins,sans-serif;
    transition: all 0.5s ease;
    letter-spacing: 2px;
    font-weight: 500;
  }

  h2 {
    font-size:3rem;
  }

  button {
    font-family: Poppins,sans-serif !important; 
    font-weight: 500;
  }



  p, a, span, textarea, li, label  {
    font-size: 0.9rem;
    line-height: 1.2;
    letter-spacing:1px;
    font-family: Poppins,sans-serif !important; 
    font-weight: 300;


    @media screen and (min-width: 768px) {
      //font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  h2 {
    font-size: 1rem;
    letter-spacing: 1.8px;

    @media screen and (min-width: 768px) {
     
    }
  }

  h3 {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    margin: 30px 0 -15px 0;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  a {
    text-decoration: none;
  }

  .page-hero-img {
    height:40vh;
    width:100%;
    border-radius:0;
    position:relative;
    @media screen and (min-width: 768px) {
      height:50vh;
    }
  
    :before {
      content:"";
      height:100%;
      position:absolute;
      top:0;
      width:100%;
      background:rgba(0,0,0,.6);
      z-index:1;
    }
    @media screen and (min-width: 768px) {
      .page-hero-img {
        height:60vh;
      }

      }
  }
    

  .subpage-content {
    text-align:center;
    background: #222629;

    h1 {
      position: relative;
      color: white;
      text-align: left;
      width:70%;
      margin:0 auto;
      left:0;
      font-size: 2rem;
      z-index: 3;
      padding-left: 15px;
      font-weight: 200;
    }

    p {
      text-transform: uppercase;
        color: white;
        margin:0 auto;
        padding-top:15px;
        padding-left: 15px;
        font-size:.7rem;
        text-align:left;
        width: 70%;

        span {
          font-size:.7rem;
          color: ${props => props.theme.colors.prime};
        }
    }
    .subpage-text {
      width:70%;
      padding-bottom:35px;
      font-family: 'Nunito', sans-serif;
      text-align:left;
      margin:0 auto;
      color: white;

      div {
        h3 {
          margin:0;
        }
        p {
          text-transform: none;
          font-size:1rem;
          font-weight:200;
          padding:0;
          margin:0;
          width: calc(100% - 30px);
        }
      }

      h2 {
        text-align:left;
        color: white;
        font-size:1rem;
        letter-spacing:1.8px;
        width: calc(100% - 15px);
      }

      a {
        color: ${props => props.theme.colors.prime};
      }
      
      .offert-text-holder {
        display:flex;
        flex-direction:column;
        width:100%;

        @media screen and (min-width: 768px) {
         flex-direction:row;
          }

        >div {
          width:100%;
          font-family: 'Nunito', sans-serif;
          font-size:1.2rem;
          

          @media screen and (min-width: 768px) {
            width:50%;
            padding:35px;
            }

          p {
            font-size:1.2rem;
            
          }
        }

        ul {
          
          width: 100%;
          text-align: center;
          margin-top: 35px;
          font-size: 1.2rem;

          @media screen and (min-width: 768px) {
            text-align:left;
            width:50%;
            margin:0;
            padding: 35px;

            }

          li {
            list-style-type:none;
            font-family: 'Nunito', sans-serif;
            padding:5px;
            :nth-child(odd) {
              background:#f7f7f7;
            }
          }
        }
        
      }
    }
    @media screen and (min-width: 768px) {
      .subpage-content {
        h1 {
          font-size:4rem;
          width:70%;
          margin:0 auto;
          left:0;
        }
        
      }

      .subpage-text {
        padding-left:15px;

      }
    
    }
  }

`

